var render = function render(){
  var _vm$created_spec, _vm$created_spec$prod, _vm$created_spec$prod2, _vm$created_spec2, _vm$created_spec2$pro, _vm$created_spec$prod3, _vm$created_spec3, _vm$created_spec3$pro, _vm$created_spec3$pro2, _vm$created_spec$prod4, _vm$created_spec$prod5, _vm$created_spec$prod6;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-tr', {
    staticClass: "b-handler-product"
  }, [_c('b-td', {
    attrs: {
      "colspan": "2"
    }
  }), _c('b-td', {
    staticClass: "text-left"
  }, [_c('div', {
    staticClass: "b-supplier"
  }, [_vm._v(" " + _vm._s((_vm$created_spec = _vm.created_spec) === null || _vm$created_spec === void 0 ? void 0 : (_vm$created_spec$prod = _vm$created_spec.product) === null || _vm$created_spec$prod === void 0 ? void 0 : _vm$created_spec$prod.name) + " ")]), _c('div', {
    staticClass: "b-name article"
  }, [_vm._v(" " + _vm._s((_vm$created_spec$prod2 = (_vm$created_spec2 = _vm.created_spec) === null || _vm$created_spec2 === void 0 ? void 0 : (_vm$created_spec2$pro = _vm$created_spec2.product) === null || _vm$created_spec2$pro === void 0 ? void 0 : _vm$created_spec2$pro.articul) !== null && _vm$created_spec$prod2 !== void 0 ? _vm$created_spec$prod2 : '------') + " ")])]), _c('b-td', [_c('div', {
    staticClass: "count"
  }, [_c('b-form-input', {
    ref: "delta",
    staticClass: "text-center",
    attrs: {
      "id": "total_amount",
      "is-keyup": true,
      "type": "number"
    },
    on: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.handlerTotalAmount.apply(null, arguments);
      }
    },
    model: {
      value: _vm.created_spec.delta,
      callback: function ($$v) {
        _vm.$set(_vm.created_spec, "delta", $$v);
      },
      expression: "created_spec.delta"
    }
  })], 1)]), _c('b-td', {
    staticClass: "center"
  }, [_vm._v(" " + _vm._s((_vm$created_spec$prod3 = (_vm$created_spec3 = _vm.created_spec) === null || _vm$created_spec3 === void 0 ? void 0 : (_vm$created_spec3$pro = _vm$created_spec3.product) === null || _vm$created_spec3$pro === void 0 ? void 0 : (_vm$created_spec3$pro2 = _vm$created_spec3$pro.measurement) === null || _vm$created_spec3$pro2 === void 0 ? void 0 : _vm$created_spec3$pro2.name) !== null && _vm$created_spec$prod3 !== void 0 ? _vm$created_spec$prod3 : '–') + " ")]), _c('b-td', [_c('div', {
    staticClass: "count"
  }, [_c('b-form-input', {
    ref: "purchase",
    staticClass: "text-center",
    attrs: {
      "id": "purchase_price",
      "is-keyup": true,
      "type": "number"
    },
    on: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.handlerPurchasePrice.apply(null, arguments);
      }
    },
    model: {
      value: _vm.created_spec.retailPrice,
      callback: function ($$v) {
        _vm.$set(_vm.created_spec, "retailPrice", $$v);
      },
      expression: "created_spec.retailPrice"
    }
  })], 1)]), _c('b-td', [_c('div', {
    staticClass: "count"
  }, [_c('b-form-input', {
    ref: "summPosition",
    staticClass: "text-center",
    attrs: {
      "id": "summ_position",
      "value": _vm.created_spec.retailSum,
      "is-keyup": true,
      "type": "number"
    },
    on: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.handlerSummPossition.apply(null, arguments);
      },
      "focus": function ($event) {
        return $event.target.select();
      }
    }
  })], 1)]), _c('b-td', [_vm._v(_vm._s(_vm.formatPrice((_vm$created_spec$prod4 = _vm.created_spec.product) === null || _vm$created_spec$prod4 === void 0 ? void 0 : _vm$created_spec$prod4.retailPrice)) + " ₽")]), _c('b-td', [_vm._v(" " + _vm._s((_vm$created_spec$prod5 = (_vm$created_spec$prod6 = _vm.created_spec.product.taxRate) === null || _vm$created_spec$prod6 === void 0 ? void 0 : _vm$created_spec$prod6.name) !== null && _vm$created_spec$prod5 !== void 0 ? _vm$created_spec$prod5 : '-') + " ")]), _c('b-td', [_vm._v(_vm._s(_vm.created_spec.taxRateSum) + " ₽")]), _c('b-td', [_c('div', {
    staticClass: "d-flex align-items-center justify-content-center"
  }, [_vm._v(" " + _vm._s(_vm.calcRemainders(_vm.created_spec.product.remainders)) + " ")])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }