<template>
  <div class="b-table__wrapper">
    <modal-costs-add @costs_add="totalUpdateCosts" />
    <modal-price-up @price_up="totalUpdateMarkup" />
    <modal-print-price-tags :products="printProducts" />
    <product-movement-modal
      :product="document?.paginationSpecifications?.specification?.find((el) => el.id === selected[0])?.product"
    />
    <div
      class="b-table__content mt-3"
      style="position: relative"
    >
      <outgoing-navbar
        style="position: absolute; bottom: 0; right: 0; left: 0"
        :items="isAllSelected ? document?.paginationSpecifications?.total : selected?.length ?? 0"
        :clear_selected="clearSelected"
        @edit_product="editProduct"
        @remove_items="removeSpecs"
      />
      <div class="table-filter">
        <b-form-input
          type="text"
          class="filter-search"
          placeholder="Поиск"
          :is-keyup="true"
          @input="search"
        />
        <b-button
          class="btn-more ml-3"
          @click="openSortModal"
        >
          <img
            src="/img/icons/settings.svg"
            alt=""
          />
        </b-button>
      </div>
      <div class="table-inner">
        <b-table-simple
          ref="incoming_table"
          :items="document.paginationSpecifications.specification"
          hover
          class="table-docs table-fixed"
          fixed
          sticky-header
          no-border-collapse
          table-class="incoming-table w-100"
        >
          <b-thead>
            <b-tr>
              <b-th
                v-for="(td, index) in fields.filter((el) => el.checked)"
                :key="index"
                :class="td.class + ' field-column unselectable text-center'"
                :style="{
                  'min-width': td.width + 'px',
                  width: td.width + 'px'
                }"
              >
                {{ td.label }}
                <img
                  v-if="td.sortable"
                  src="/img/icons/icon-sorting.svg"
                  class="cursor img-sorting"
                  alt=""
                />
                <b-form-checkbox
                  v-if="td.key === 'id'"
                  v-model="isAllSelected"
                />
                <div
                  class="resize-field"
                  @mousedown="(el) => mouseDown(el, td)"
                >
                  &nbsp;
                </div>
              </b-th>
            </b-tr>
          </b-thead>
          <b-tbody class="incoming-table-body">
            <b-tr v-if="busy">
              <td :colspan="fields.length">
                <div class="text-center">
                  <b-spinner
                    variant="primary"
                    label="Загрузка..."
                  />
                  <div class="mt-2"><strong>Загрузка...</strong></div>
                </div>
              </td>
            </b-tr>

            <b-tr
              v-for="(tr, tr_index) in document.paginationSpecifications.specification"
              :key="tr_index"
            >
              <b-td
                v-for="(field, index) of fields.filter((el) => el.checked)"
                :key="index"
                style="overflow: hidden"
              >
                <template v-if="field.key === 'id'">
                  <b-form-checkbox
                    v-model="selected"
                    class="cursor"
                    :value="tr.id"
                  />
                </template>

                <template v-if="field.key === 'order'">
                  {{ tr.order }}
                </template>

                <template v-if="field.key === 'product'">
                  <div class="text-left">
                    {{ tr.product.name }}
                    <div class="b-name article">
                      {{ tr.product.articul || 'Не указан' }}
                    </div>
                  </div>
                </template>

                <template v-if="field.key === 'delta'">
                  <table-row-input
                    :ref="tr.id + field.key"
                    :value="tr.delta"
                    :fixed="document.fixed"
                    :input_id="tr.id + tr.key"
                    :digits="3"
                    @handle-input="(val) => setDelta(val, tr)"
                    @input="(val) => setDelta(val, tr)"
                    @on-focus="centeredScroll"
                  />
                </template>

                <template v-if="field.key === 'measurement'">
                  {{ tr.product.measurement.name || '–' }}
                </template>

                <template v-if="field.key === 'price'">
                  <table-row-input
                    :value="tr.price"
                    :fixed="document.fixed"
                    symbol="₽"
                    :input_id="tr.id + field.key"
                    @handle-input="(val) => setPrice(val, tr, true)"
                    @input="(val) => setPrice(val, tr)"
                    @on-focus="centeredScroll"
                  />
                </template>

                <template v-if="field.key === 'retailSum'">
                  <table-row-input
                    :value="tr.retailSum"
                    :fixed="document.fixed"
                    symbol="₽"
                    :input_id="tr.id + field.key"
                    @handle-input="(val) => setRetailSum(val, tr)"
                    @on-focus="centeredScroll"
                  />
                </template>

                <template v-if="field.key === 'markup'">
                  <table-row-input
                    :value="tr.markup"
                    :fixed="document.fixed"
                    symbol="%"
                    :input_id="tr.id + field.key"
                    @handle-input="(val) => setMarkup(val, tr)"
                    @input="(val) => setMarkup(val, tr)"
                    @on-focus="centeredScroll"
                  />
                </template>

                <template v-if="field.key === 'retailPrice'">
                  <table-row-input
                    :value="tr.retailPrice"
                    :fixed="document.fixed"
                    symbol="₽"
                    :input_id="tr.id + field.key"
                    @handle-input="(val) => setRetailPrice(val, tr, true)"
                    @input="(val) => setRetailPrice(val, tr)"
                    @on-focus="centeredScroll"
                  />
                </template>

                <template v-if="field.key === 'currentPrice'">
                  {{ formatPrice(tr.product?.retailPrice ?? 0) }} ₽
                </template>

                <template v-if="field.key === 'nds'">
                  {{ tr.nds.name }}
                </template>

                <template v-if="field.key === 'taxRateSum'"> {{ +tr.taxRateSum?.toFixed(2) || 0 }} ₽ </template>

                <template v-if="field.key === 'remainders'">
                  {{ getRemainder(tr.product.remainders) }}
                </template>

                <template v-if="field.key === 'costs'">
                  {{ +tr.cost?.toFixed(2) || 0 }}
                </template>
              </b-td>
            </b-tr>

            <b-tr v-if="document.paginationSpecifications?.specification?.length === 0 && !busy">
              <td :colspan="fields.length">
                <p class="center mt-3">Нет товаров</p>
              </td>
            </b-tr>
            <add-cashless-spec
              v-if="handler_product"
              :spec="specToCreate"
              @add_product="createSpec"
              @remove_prod="removeProduct"
              @on-focus="centeredScroll"
            />

            <b-tr v-if="!document.fixed && !scrollExist">
              <b-td :colspan="fields.filter((el) => el.checked).length">
                <div class="d-flex">
                  <product-search
                    v-if="!document.fixed && !scrollExist"
                    :supplier="document?.supplier?.id ?? null"
                    :document_head="document.id"
                    :document="document"
                    :document_type="'incoming'"
                    @select_product="select_product"
                  />
                </div>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </div>

      <div class="table-footer incoming-table-footer align-middle d-flex align-items-center p-3">
        <div style="width: 45px"><span></span></div>
        <div style="width: 45px"><span></span></div>
        <div class="mr-auto">
          <product-search
            v-if="!document.fixed && scrollExist"
            :supplier="document?.supplier?.id ?? null"
            style="width: 250px"
            class="ml-2"
            :document_head="document.id"
            :document="document"
            document_type="outgoing"
            @select_product="select_product"
          />
        </div>

        <div class="footer-data">
          Итого:
          {{ document.paginationSpecifications?.total }}
        </div>

        <div class="footer-data">
          Количество:
          <span>
            {{ +document.paginationSpecifications?.factTotal?.toFixed(2) || 0 }}
          </span>
        </div>

        <div class="footer-data">
          Сумма:
          <span>
            {{ formatPrice(document.retailSum) }}
            ₽
          </span>
        </div>

        <!--        <div class="footer-data">-->
        <!--          Наценка:-->
        <!--          <span>-->
        <!--            {{-->
        <!--              +(document.paginationSpecifications?.avgMarkup * 100).toFixed(-->
        <!--                2-->
        <!--              ) || 0-->
        <!--            }}-->
        <!--            %-->
        <!--          </span>-->
        <!--        </div>-->

        <div class="footer-data">
          Сумма с НДС:
          {{ formatPrice(document.paginationSpecifications?.taxRateSum) }}
          ₽
        </div>
      </div>
    </div>
    <sort-table-modal
      :fields="copyFields"
      :default_fields="default_fields"
      @change="changeFields"
      @set-default="setDefault"
    />
  </div>
</template>

<script>
  import ModalPrintPriceTags from '@/views/core/modal/ModalPrintPriceTags'
  import ModalPriceUp from '@/views/operational-processes/components/overhead/ModalPriceUp'
  import { mapGetters } from 'vuex'
  import ModalCostsAdd from '@/views/operational-processes/components/overhead/ModalCostsAdd'
  import TableRowInput from '@/views/operational-processes/components/TableRowInput'
  import { TaxRateModel } from '@/models/tax-rate.model'
  import SortTableModal from '@/views/operational-processes/components/SortTableModal'
  import AddCashlessSpec from '@/views/operational-processes/components/cashless/AddCashlessSpec'
  import ProductSearch from '@/views/operational-processes/components/ProductSearch'
  import { ProductModel } from '@/models/product.model'
  import { OutgoingHeadModel } from '@/views/operational-processes/models/outgoing-head.model'
  import { OutgoingSpecificationModel } from '@/views/operational-processes/models/outgoing-spec.model'
  import OutgoingNavbar from '@/views/operational-processes/components/outgoing/OutgoingNavbar'
  import ProductMovementModal from '@/views/products/modal/ProductMovementModal.vue'

  export default {
    components: {
      ProductMovementModal,
      OutgoingNavbar,
      ProductSearch,
      SortTableModal,
      AddCashlessSpec,
      TableRowInput,
      ModalPrintPriceTags,
      ModalPriceUp,
      ModalCostsAdd
    },
    props: {
      document: {
        type: OutgoingHeadModel
      },
      busy: {
        type: Boolean
      }
    },
    data() {
      return {
        isUpdateFilters: false,
        searchString: '',
        search_result: false,
        fields: [],
        printProducts: [],
        specToCreate: new OutgoingSpecificationModel(),
        handler_product: false,
        default_fields: [
          {
            key: 'id',
            label: '',
            isRowHeader: true,
            class: 'center b-checkbox',
            thClass: 'white-bg',
            checked: true,
            width: 45
          },
          {
            key: 'order',
            label: '№',
            class: 'center',
            checked: true,
            width: 54,
            thClass: 'white-bg'
          },
          {
            key: 'product',
            label: 'Наименование товара',
            checked: true,
            class: 'text-left td-name',
            width: 250,
            thClass: 'white-bg'
          },
          {
            key: 'delta',
            label: 'Общее количество',
            class: 'center',
            checked: true,
            width: 100,
            thClass: 'white-bg'
          },
          {
            key: 'measurement',
            label: 'Ед. изм.',
            class: 'center',
            checked: true,
            width: 54,
            formatter: (value, key, item) => item.product?.measurement?.name ?? '-'
          },
          {
            key: 'retailPrice',
            label: 'Цена продажи',
            class: 'center',
            checked: true,
            width: 150,
            thClass: 'white-bg'
          },
          {
            key: 'retailSum',
            label: 'Сумма по позиции',
            class: 'center',
            checked: true,
            width: 150
          },
          {
            key: 'currentPrice',
            label: 'Розница текущая',
            class: 'center',
            checked: true,
            width: 120
          },
          {
            key: 'nds',
            label: 'Ставка НДС',
            class: 'center',
            checked: true,
            width: 120
          },
          {
            key: 'taxRateSum',
            label: 'Сумма НДС (₽)',
            class: 'center',
            checked: true,
            width: 120
          },
          {
            key: 'remainders',
            label: 'Остаток',
            class: 'center',
            checked: true,
            width: 100,
            formatter: (value, key, item) => this.getRemainder(item.product.remainders)
          }
        ],
        selected: [],
        isAllSelected: false,
        taxRateList: [],
        thElm: 0,
        startOffset: 0,
        field: null,
        scrollExist: true,
        isModalOpened: false
      }
    },

    apollo: {
      TaxRates: {
        query: require('../../gql/getTaxRates.gql'),
        result({ data }) {
          this.taxRateList = data?.TaxRates?.map((el) => new TaxRateModel(el)) ?? []
        },
        error(error) {
          console.error('Ошибка запроса: ', error)
        }
      }
    },

    computed: {
      ...mapGetters({
        currentBranch: 'settings/getCurrentBranch'
      }),
      copyFields() {
        return JSON.parse(JSON.stringify(this.fields))
      }
    },

    watch: {
      isAllSelected(newVal) {
        if (newVal) {
          this.selected =
            this.document.paginationSpecifications?.specification?.map((el) => {
              return el.id
            }) ?? []
        } else this.selected = []
      }
    },

    mounted() {
      this.$nextTick(() => this.tableResize())
      const srtFields = localStorage.getItem('cashless_fields')
      this.fields = srtFields ? JSON.parse(srtFields) : JSON.parse(JSON.stringify(this.default_fields))
      document.addEventListener(
        'click',
        (e) => {
          this.listenerClick(e)
        },
        false
      )

      this.loadFields()
      this.$nextTick(() => this.scrollListener())
    },

    destroyed() {
      this.isUpdatedStorage = false
      document.removeEventListener('click', () => console.info('removeListener'))
    },

    methods: {
      editProduct() {
        const name = this.document.paginationSpecifications?.specification?.find(
          (obj) => (obj.product.id = this.selected[0])
        )?.product?.name
        this.$router.push({ name: 'products.edit', params: { id: this.selected[0], name } })
      },
      scrollDown(event) {
        let bottomOfWindow = event.target.scrollTop + 100 + event.target.clientHeight >= event.target.scrollHeight
        if (event.target.scrollTop === 0) {
          this.$emit('scroll-up', bottomOfWindow)
        } else if (bottomOfWindow) {
          this.$emit('scroll-down')
        }
      },
      async loadFields() {
        const { data } = await this.$apollo.query({
          query: require('../../gql/ReadUserTable.graphql')
        })
        const table = data?.ReadUserTable?.tables?.find((el) => el.table_name === 'cashless')
        if (table?.fields) this.fields = table.fields
      },
      scrollListener() {
        const resizeObserver = new ResizeObserver(() => {
          const table = document.querySelector('.table-docs')

          this.scrollExist =
            table.offsetHeight <= document.querySelector('.incoming-table').clientHeight ||
            (table.offsetHeight <= document.querySelector('.incoming-table').clientHeight + 55 && this.scrollExist)
        })

        resizeObserver.observe(document.querySelector('.incoming-table'), {
          childList: true,
          subtree: true
        })
        document.querySelector('.table-docs').addEventListener('scroll', this.scrollDown)
        // window.removeEventListener('scroll', this.handleScroll)
      },
      centeredScroll({ offset }) {
        const clientWidth = document.querySelector('.table-docs').clientWidth
        document.querySelector('.table-docs').scrollTo({ left: offset - clientWidth / 2, behavior: 'smooth' })
      },
      mouseDown(e, field) {
        this.thElm = e.target.parentNode
        this.startOffset = this.thElm.offsetWidth - e.pageX
        this.field = field
      },
      tableResize() {
        document.addEventListener('mousemove', (e) => {
          if (this.thElm && this.startOffset + e.pageX > 0) {
            this.thElm.style.minWidth = this.startOffset + e.pageX + 'px'
            this.thElm.style.width = this.startOffset + e.pageX + 'px'
            this.field.width = this.startOffset + e.pageX
          }
        })

        document.addEventListener('mouseup', () => {
          if (!this.thElm) return
          this.field.thStyle = this.thElm.style.cssText
          this.saveFields()
          this.startOffset = 0
          this.thElm = undefined
        })
      },
      setDefault() {
        this.fields = JSON.parse(JSON.stringify(this.default_fields))
        this.saveFields()
      },
      saveFields() {
        localStorage.setItem('cashless_fields', JSON.stringify(this.fields))
        this.$apollo.mutate({
          mutation: require('../../gql/saveFields.graphql'),
          variables: {
            input: {
              table_name: 'cashless',
              fields: this.fields.map((el) => {
                return {
                  key: el.key,
                  checked: el.checked,
                  width: el.width,
                  label: el.label
                }
              })
            }
          }
        })
      },
      openSortModal() {
        this.$bvModal.show('sort-table-modal')
      },
      getRemainder(item) {
        const storage_id = this.document.storage.id

        if (Array.isArray(item)) {
          const num = item.filter((el) => el?.storage?.id === storage_id).reduce((sum, el) => sum + el.value, 0) || 0
          return num % 1 === 0 ? num : num?.toFixed(3)
        }

        return 0
      },

      setDelta(val, item, isEnter) {
        item.setDelta(val)
        if (isEnter) document.getElementById(item.id + 'retailPrice')?.click()
        this.updateSpec(item)
      },

      setPrice(val, item, isEnter) {
        item.setPrice(val)
        if (isEnter) document.getElementById(item.id + 'sum')?.click()
        this.updateSpec(item)
      },

      setRetailSum(val, item) {
        item.setRetailSum(val)
        this.updateSpec(item)
      },

      setSum(val, item) {
        item.setSum(val)
        this.updateSpec(item)
      },

      setMarkup(val, item) {
        item.setMarkup(val)
        this.updateSpec(item)
      },

      setRetailPrice(val, item, isEnter) {
        item.setRetailPrice(val)
        if (isEnter) document.getElementById(item.id + 'retailSum')?.click()
        this.updateSpec(item)
      },

      setNds(val, item) {
        item.setNds(val)

        this.updateSpec(item)
      },

      formatPrice(summ = 0) {
        const val = summ === null ? 0 : summ

        const formatter = new Intl.NumberFormat('ru-RU', {
          style: 'decimal',
          currency: 'RUB',
          maximumFractionDigits: 2,
          minimumFractionDigits: 0
          // These options are needed to round to whole numbers if that's what you want.
          //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
          // maximumFractionDigits: 0 // (causes 2500.99 to be printed as $2,501)
        })

        return formatter.format(val)
      },

      searchProductResult(data) {
        const result = data.ProductSearch?.products ?? []

        if (result.length === 1) {
          this.search_result = false
          this.select_product(result[0])
        }

        return data
      },
      scroll_to(item) {
        const spec = this.document.paginationSpecifications.specification.find((el) => el.id === item.specification.id)
        if (item.found_by_barcode && item.brutto) {
          spec.setDelta(spec.delta + item.brutto)
          this.updateSpec(spec)
          return
        }

        setTimeout(() => {
          this.$refs[item.specification.id + 'delta'][0].showInput()
        }, 100)
        document.querySelector('.table-docs').scrollTo({
          top: this.$refs[item.specification.id + 'delta'][0].$el.offsetTop - 180
        })
      },
      select_product(item) {
        if (item.specification) {
          if (!this.$refs[item.specification.id + 'delta']) {
            this.$emit('get_by_order', item.specification, () => this.scroll_to(item))
          } else {
            this.scroll_to(item)
          }
          return
        }

        const packageCount = item.package ? item.package[0]?.value : 0
        let delta = 0
        if (item.found_by_barcode && item.specification)
          delta = item.specification?.delta ? (item.specification?.delta || 0) + (packageCount || 1) : packageCount

        if (item.brutto && item.found_by_barcode) delta = item.brutto

        const new_spec = new OutgoingSpecificationModel({
          ...item.specification,
          delta: item.specification?.delta ? (item.specification?.delta || 0) + (packageCount || 1) : packageCount,
          product: item,
          document_head: this.document,
          nds: item.taxRate
        })
        new_spec.setDelta(delta)

        new_spec.setPrice(new_spec.product.purchasePrice || 0)
        if (!new_spec.product.markup && new_spec.product.retailPrice) {
          new_spec.setRetailPrice(new_spec.product.retailPrice)
        }

        this.createSpec(new_spec.input)
      },
      async createSpec(spec) {
        const { data } = await this.$apollo.mutate({
          mutation: require(`../../gql/CreateOutgoingSpecification.gql`),
          variables: {
            productChange: [spec],
            document_head: this.document.id
          }
        })

        this.$emit('get-last', () => {
          setTimeout(() => {
            document.querySelector('.table-docs').scrollTo({
              top: document.querySelector('.table-docs').scrollHeight,
              left: 0
            })
          }, 500)
          this.$nextTick(() => {
            this.$refs[data.CreateOutgoingSpecification[0].id + 'delta'][0].showInput()
          })
        })
        this.$noty.show('Добавлено')
        this.removeProduct()
        document.querySelector('.table-docs').scrollTo({ left: 0, behavior: 'smooth' })
      },

      async updateSpec(spec) {
        await this.$apollo.mutate({
          mutation: require(`../../gql/CreateOutgoingSpecification.gql`),
          variables: {
            productChange: [spec.cashless_input],
            document_head: this.document.id
          }
        })
      },

      async removeSpecs() {
        const { data } = await this.$apollo.mutate({
          mutation: require('../../gql/RemoveOutgoingSpecification.gql'),
          variables: {
            document_head: this.document.id,
            ids: this.selected,
            isAllSelected: this.isAllSelected
          }
        })
        if (data?.RemoveOutgoingSpecification?.status) {
          this.isAllSelected = false
          this.selected = []
          this.$emit('update-data')
        }
      },

      removeProduct() {
        this.specToCreate = {}
        this.handler_product = false
      },

      setSupplierError(status = false) {
        this.isSupplierError = status
      },
      counterSeconds() {
        this.time = this.time + 0.1
      },

      resetTimer() {
        this.time = 0
      },

      clearSelected() {
        this.isAllSelected = false
        this.selected = []
      },

      // Action's
      back() {
        this.filter_params = this.filtersData()
        this.$nextTick(() => this.updateDocument())
      },
      listenerClick(e) {
        const target = e.target.classList.value

        if (
          target === 'cursor' ||
          target === 'span-count' ||
          target === 'span-count error' ||
          target === 'edit' ||
          target === 'ivu-select-placeholder' ||
          target === 'ivu-icon ivu-icon-ios-arrow-down ivu-select-arrow' ||
          target === 'form-control'
        )
          return true
      },

      async printPriceTags() {
        let products = []
        for (const prod of this.selected) {
          const found = this.document.paginationSpecifications.specification.find((el) => el.id === prod)
          if (found) {
            products.push(found.product.id)
          }
        }
        const { data } = await this.$apollo.query({
          query: require('../../gql/ReadPriceTagsFromDocument.graphql'),
          fetchPolicy: 'no-cache',
          variables: {
            input: {
              document_head: this.document.id,
              branch: this.document.branch.id,
              product: products,
              isPrintAll: this.isAllSelected
            }
          }
        })
        this.printProducts = data?.ReadPriceTagsFromDocument?.map((el) => {
          return new ProductModel({ ...el?.Product, retailPrice: el?.value ?? el?.Product?.retailPrice })
        })
        if (this.printProducts?.length > 0) this.$bvModal.show('modal-print-tags')
      },
      changeFields(val) {
        this.fields = val
        localStorage.setItem('cashless_fields', JSON.stringify(this.fields))
      },

      showPriceUp() {
        this.$bvModal.show('modal-price-up')
      },

      async totalUpdateMarkup(priceup) {
        const { data } = await this.$apollo.mutate({
          mutation: require('../../gql/AddMarkup.graphql'),
          variables: {
            input: {
              document_head: this.document.id,
              ids: this.selected,
              isAllSelected: this.isAllSelected,
              markup: +priceup.value,
              roundTo: priceup.priceup
            }
          }
        })
        if (data?.AddMarkup?.status) {
          this.$bvModal.hide('modal-price-up')
          this.isAllSelected = false
          this.selected = []
          this.$emit('update-data')
        }
      },
      async totalUpdateCosts(costsadd) {
        const { data } = await this.$apollo.mutate({
          mutation: require('../../gql/AddCosts.graphql'),
          variables: {
            input: {
              document_head: this.document.id,
              ids: this.selected,
              isAllSelected: this.isAllSelected,
              costs: +costsadd.value,
              addParams: costsadd.addParams
            }
          }
        })
        if (data?.AddCosts?.status) {
          this.$bvModal.hide('modal-costs-add')
          this.isAllSelected = false
          this.selected = []
          this.$emit('update-data')
        }
      },
      search(value) {
        this.$emit('search', value)
      }
    }
  }
</script>
<style scoped lang="scss">
  ::v-deep() {
    .table-hover tbody tr:hover td,
    .table-hover tbody tr:hover th {
      background-color: white !important;
    }

    .table-hover tbody tr:hover td,
    .table-hover tbody tr:hover th {
      background-color: #f8f8f9 !important;
      transition: 1000ms ease;

      & .count {
        background-color: transparent;
        transition: none ease-in;
      }
    }

    .table-docs thead th {
      border-bottom: 1px solid #e1e1e1;
      border-right: 1px solid #e1e1e1;
      border-top: 1px solid #e1e1e1 !important;
    }

    .table-footer th {
      vertical-align: middle !important;
    }
  }

  .table-hover tbody tr:hover td,
  .table-hover tbody tr:hover th {
    background-color: white !important;
  }

  .table-hover tbody tr:hover td,
  .table-hover tbody tr:hover th {
    background-color: #f8f8f9 !important;
    transition: 1000ms ease;

    & .count {
      background-color: transparent;
      transition: none ease-in;
    }
  }

  .resize-field {
    top: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    cursor: col-resize;
  }

  .field-column {
    position: relative;
  }

  .incoming-table {
    table-layout: fixed;
    width: auto !important;
  }

  table.fixed td {
    overflow: hidden;
  }

  .unselectable {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Chrome/Safari/Opera */
    -khtml-user-select: none; /* Konqueror */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                 not supported by any browser */
  }

  // .table-docs thead th {
  //   border-bottom: 1px solid #e1e1e1;
  //   border-right: 1px solid #e1e1e1;
  //   border-top: 1px solid #e1e1e1 !important;
  // }

  .incoming-table {
    border: none !important;
  }

  // .table-footer th {
  //   vertical-align: middle !important;
  // }

  .footer-data {
    color: #313131;
    margin-left: 32px;
  }

  .incoming-table-footer {
    border-top: 1px solid #e1e1e1 !important;
  }
</style>
