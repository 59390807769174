<template>
  <div>
    <div class="b-table__filter d-flex justify-content-between">
      <div class="b-toggle-button">
        <e-button
          size="m"
          class="btn-filters cursor"
          @click="changeVisibleParams"
        >
          Параметры документа
          <img
            class="ml-3"
            :class="{ rotate: visibleParams }"
            src="/img/icons/arrow_up.svg"
            alt=""
          />
        </e-button>
      </div>
      <div class="b-action-buttons d-flex">
        <e-button
          v-if="!document.fixed"
          size="m"
          variant="outline-primary"
          class="cursor"
          @click="back"
        >
          Сохранить черновик
        </e-button>
        <e-button
          size="m"
          variant="primary"
          class="cursor ml-3"
          :disabled="loading"
          :loading="loading"
          @click="updateDocument(document.fixed)"
        >
          <template v-if="is_request_update">
            <b-spinner
              variant="light"
              small
            />
          </template>
          <template v-else>
            {{ document.fixed ? 'Редактировать' : 'Провести' }}
          </template>
        </e-button>
        <b-dropdown
          no-caret
          variant="none"
          class="ml-3"
        >
          <template #button-content>
            <div class="btn btn-white btn-more">
              <img
                src="/img/icons/more.svg"
                alt=""
                width="12"
                height="12"
              />
            </div>
          </template>
          <b-dropdown-item @click="printUpd">Печать</b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
    <e-collapse
      id="collapse-filters"
      v-model="visibleParams"
      :show-button="false"
    >
      <template #content>
        <filters-cashless
          ref="header_filters_outgoing"
          :fixed="document?.fixed"
          :filter_params="document"
          :is_supplier_error="isSupplierError"
          :is_entity_error="isEntityError"
          :is_storage_error="isStorageError"
          :set_entity_error="() => (this.isEntityError = null)"
          :set_supplier_error="() => (this.isSupplierError = null)"
          @change="change"
        />
      </template>
    </e-collapse>
  </div>
</template>

<script>
  import { OutgoingHeadModel } from '@/views/operational-processes/models/outgoing-head.model'
  import FiltersCashless from '@/views/operational-processes/components/cashless/FiltersCashless'
  import { http } from '@/http/http'
  import { print_html } from '@/utils/print'

  export default {
    name: 'CashlessHead',
    components: {
      FiltersCashless
    },
    props: {
      document: {
        type: OutgoingHeadModel
      },
      loading: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        visibleParams: false,
        isSupplierError: null,
        isEntityError: null,
        isStorageError: null,
        is_request_update: false,
        verificationItems: {
          supplier: 'поставщика',
          entity: 'организацию',
          storage: 'место хранения'
        }
      }
    },
    destroyed() {
      localStorage.setItem('newDocHeader', 'false')
    },
    beforeMount() {
      this.visibleParams =
        localStorage.getItem('newDocHeader') === 'true' || localStorage.getItem('docHeader') === 'true'
    },
    methods: {
      changeVisibleParams() {
        this.visibleParams = !this.visibleParams
        if (this.visibleParams) {
          localStorage.setItem('docHeader', 'true')
        } else {
          localStorage.setItem('docHeader', 'false')
        }
      },
      updateDocument(status) {
        if (!this.document.supplier?.id && !this.document.entity?.id && !this.document.storage?.id) {
          this.isSupplierError = true
          this.isEntityError = true
          this.isStorageError = true
          return this.$noty.error(`Необходимо выбрать ${this.generateTextError(this.document, this.verificationItems)}`)
        }
        if (!this.document.supplier?.id) {
          this.isSupplierError = true
          return this.$noty.error(`Необходимо выбрать ${this.generateTextError(this.document, this.verificationItems)}`)
        } else {
          this.isSupplierError = false
        }
        if (!this.document.storage?.id) {
          this.isStorageError = true
          return this.$noty.error(`Необходимо выбрать ${this.generateTextError(this.document, this.verificationItems)}`)
        } else {
          this.isStorageError = false
        }
        if (!this.document.entity?.id) {
          this.isEntityError = true
          return this.$noty.error(`Необходимо выбрать ${this.generateTextError(this.document, this.verificationItems)}`)
        } else {
          this.isEntityError = false
          this.$emit('makeUsed', !status)
        }
      },
      generateTextError(document, verificationItems) {
        let listWords = []
        for (let key in verificationItems) {
          if (!document[key].id) {
            listWords.push(verificationItems[key])
          }
        }
        return listWords.join(', ')
      },

      makeFixed(status) {
        this.$emit('makeUsed', status)
      },
      back() {
        this.$emit('onChange')
        this.$router.back()
      },
      change() {
        this.$emit('onChange')
      },
      async printUpd() {
        try {
          const { data } = await http.get('templates/outgoing/upd?document=' + this.document.id)
          if (data) {
            print_html(data)
          } else {
            this.$noty.show('Ошибка печати')
          }
        } catch (e) {
          console.error(e)
          this.$noty.show('Ошибка печати')
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .b-table__filter {
    .b-toggle-button {
      button {
        background: #e2e2e2;
        color: #313131;

        img {
          margin-right: 0;
          transition: all 0.2s;
          transform: rotate(180deg);
        }

        &.not-collapsed {
          img {
            transform: rotate(0);
          }
        }
      }
    }
  }

  .table-filter {
    button {
      background: #efefef;
      color: #313131;

      &:hover {
        background: #efefef;
        color: #313131;
      }
    }
  }
  ::v-deep .elm-calendar input {
    height: 31px;
  }
  .rotate {
    transform: rotate(360deg) !important;
  }
</style>
