var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    attrs: {
      "id": "cashless-document"
    }
  }, [_c('cashless-head', {
    attrs: {
      "document": _vm.outgoingDocument,
      "loading": _vm.loading
    },
    on: {
      "onChange": _vm.updateHead,
      "makeUsed": _vm.makeUsed
    }
  }), _c('cashless-spec-table', {
    attrs: {
      "document": _vm.outgoingDocument,
      "busy": _vm.tableIsBusy
    },
    on: {
      "update_table": _vm.refetchData,
      "scroll-down": _vm.scrollDown,
      "scroll-up": _vm.scrollUp,
      "get-last": _vm.getLast,
      "update-data": _vm.updateData,
      "search": _vm.search,
      "get_by_order": _vm.getByOrder
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }